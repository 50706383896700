<template>
  <WelcomeNavbar v-if="$route.meta.layout === 'welcome'"/>
  <router-view/>
</template>

<script>
import WelcomeNavbar from './layouts/navbars/WelcomeNavbar.vue';


export default {
  name: 'App',
  components: 
  {
    WelcomeNavbar,
  }
}
</script>

