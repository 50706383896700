import { createApp } from 'vue';
import router from './routing/router';
import VueCodeHighlight from 'vue-code-highlight';


import App from './App.vue'
import './index.css';

const app = createApp(App);
app.use(VueCodeHighlight);
app.use(router);

app.mount('#app');
