
import { createWebHistory, createRouter } from "vue-router";
import routes from './routes';


const router = createRouter({
    history: createWebHistory(),
    routes,
    // eslint-disable-next-line
    scrollBehavior (to, from, savedPosition) {
      if (to.hash) {
        return {
          el: to.hash
        }
      }
      else{
        return {
          // could also be
          // el: document.getElementById('main'),
          el: '#App',
          top: 0,
          behavior:'instant'
        } 
      }
    },
  });
export default router;

