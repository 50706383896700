function load (component) {
    return () => import( `../${component}.vue`)
}
export default 
[
    {
        path: '',
        component: load('pages/Welcome'),
        name: 'Welcome',
        meta:
        {
            layout: 'welcome'
        }
    }
]